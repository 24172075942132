// import { useState } from "react"

// ** React Imports
import { NavLink, useLocation  } from "react-router-dom"

// ** Third Party Components
import classnames from "classnames"

// ** Reactstrap Imports
import { Badge, Collapse, UncontrolledPopover } from "reactstrap"

// ** Utils
import { isNavLinkActive } from "@layouts/utils"
import Icon from "@src/components/Icon"

import VerticalNavMenuHoverLink from './VerticalNavMenuHoverLink'

const VerticalNavMenuLink = ({ item, isChild, activeItem, menuCollapsed, isSlideNav 
  , setCurrentOpenGroup, currentOpenGroup
}) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? "a" : NavLink

  // ** Current Val
  const currentURL = useLocation().pathname
 
  return (
    <>
      <li
        className={classnames({
          "nav-item": !item.children,
          disabled: item.disabled,
          active: item.navLink === activeItem
        })}
        id={`m-${item.id}`}
       
      >
        <LinkTag
          className="d-flex align-items-center"
          target={item.newTab ? "_blank" : undefined}
          /*eslint-disable */
          {...(item.externalLink === true
            ? {
                href: item.navLink || "/",
              }
            : {
                to: item.navLink || "/",
                className: ({ isActive }) => {
                  if ((isActive || isNavLinkActive(item.navLink, currentURL, item))
                    && !item.disabled) {
                    return "d-flex align-items-center active";
                  }
                },
              })}
          onClick={(e) => {
            if (
              item.navLink.length === 0 ||
              item.navLink === "#" ||
              item.disabled === true
            ) {
              e.preventDefault();
            }
          }}
        >
          <span className="d-inline-block">
           { isSlideNav ? <></> : <Icon icon={item.icon} library={item.iconLibrary} className={item.iconClass} size={20}/>}
          </span>
          <span className="menu-item text-truncate">{item.title}</span>

          {item.badge && item.badgeText ? (
            <Badge className="ms-auto me-1" color={item.badge} pill>
              {item.badgeText}
            </Badge>
          ) : null}
        </LinkTag>

        { isChild ? <></> : !menuCollapsed ? <></> : 
        <UncontrolledPopover className="nav-popover" placement="left" trigger="hover" 
          target={`m-${item.id}`}>
          <VerticalNavMenuHoverLink 
          items={[item]}
          item={item}
          activeItem={activeItem}
          isGroup={false}
          isLabelOpen={true}
          setCurrentOpenGroup={setCurrentOpenGroup}
          currentOpenGroup={currentOpenGroup}
          />
        </UncontrolledPopover>
      }
      </li>
      
      
    </>
  );
};

export default VerticalNavMenuLink;
