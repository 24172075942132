export enum Policies {
  
  // View Policies
  CanViewHome,
  CanViewHealth,
  CanViewKnowledgeBase,
  CanViewReleaseNotes,
  CanViewRoadmap,
  CanViewAxIntelligence,
  CanViewServicePortfolios,
  CanViewReports,
  CanViewServices,
  CanViewSecOps,

  // Manage Policies
  CanManageMenu,
  CanManageSecurity,
  CanManageReportsAndServices,
  CanManageReleaseNotes,
  CanManageReports
}